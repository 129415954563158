<template>
  <b-table
    ref="refInvoiceListTable"
    :items="orders"
    responsive
    :fields="columns"
    primary-key="id"
    show-empty
    empty-text="No hay ventas para mostrar"
    class="text-center sales-table"
  >
    <template #head(invoiceStatus)>
      <feather-icon icon="TrendingUpIcon" class="mx-auto" />
    </template>

    <!-- Column: Id -->
    <template #cell(folio)="data">
      <b-link
        :to="{ name: 'order-view', params: { id: data.item.id } }"
        class="font-weight-bold"
      >
        #{{ data.item.folio || data.item.auto_inc_folio }}
      </b-link>
    </template>

    <!-- Column: Invoice Status -->
    <template #cell(invoiceStatus)="data">
      <b-avatar
        :id="`invoice-row-${data.item.id}`"
        size="32"
        :variant="`light-${
          resolveInvoiceStatusVariantAndIcon(data.item.invoiceStatus).variant
        }`"
      >
        <feather-icon
          :icon="
            resolveInvoiceStatusVariantAndIcon(data.item.invoiceStatus).icon
          "
        />
      </b-avatar>
      <b-tooltip :target="`invoice-row-${data.item.id}`" placement="top">
        <p class="mb-0">
          {{ data.item.invoiceStatus }}
        </p>
        <p class="mb-0">Balance: {{ data.item.balance }}</p>
        <p class="mb-0">Due Date: {{ data.item.dueDate }}</p>
      </b-tooltip>
    </template>

    <template #cell(store_clerk)="data">
      <b-media
        v-if="data.item.store"
        :id="`invoice-row-${data.item.id}-preview-address`"
        vertical-align="center"
      >
        <template #aside>
          <b-avatar size="32" :src="data.item.store.logo" />
        </template>
        <span class="font-weight-bold d-block text-nowrap text-left">
          {{ data.item.store.name }}
        </span>
        <small class="text-muted">{{ data.item.store_clerk.email }}</small>
      </b-media>
    </template>

    <template #cell(created_at)="data">
      <span class="text-nowrap mr-2">
        {{ data.value | dateNtime2 }}
      </span>
    </template>

    <template #cell(delivery_date)="data">
      <span class="text-nowrap mr-2" v-if="data.value">
        {{ data.value | dateNtime2 }}
      </span>
      <span class="text-danger" v-else> No asignada </span>
    </template>

    <!-- Column: Total -->
    <template #cell(total)="data">
      <div>
        <feather-icon
          :icon="data.item.order_type | orderTypeIcon"
          :class="data.item.order_type | orderTypeClass"
          class="mr-1"
        />
        ${{ data.value | money }}
      </div>
    </template>

    <!-- Column: Payment status -->
    <template #cell(payment_status)="data">
      <div>
        <b-badge
          pill
          :variant="
            data.value === 'payed'
              ? 'light-success'
              : data.value === 'pending'
              ? 'light-warning'
              : 'light-danger'
          "
        >
          {{ data.item.payment_status | paymentStatus }}
        </b-badge>
      </div>
    </template>

    <!-- Column: Delivery status -->
    <template #cell(delivery_status)="data">
      <template>
        <b-badge
          pill
          :class="data.item.delivery_status | deliveryStatusClass"
          :variant="
            data.value === 'delivered'
              ? 'light-success'
              : data.value === 'assigning' &&
                data.item.delivery_option === 'pickup'
              ? 'light-warning'
              : data.value === 'assigning'
              ? 'light-warning'
              : data.value === 'assigned'
              ? 'light-primary'
              : data.value === 'rejected_by_dispatcher' ||
                data.value === 'cancelled_by_customer' ||
                data.value === 'cancelled_by_establishment'
              ? 'light-danger'
              : 'light-info'
          "
        >
          <feather-icon
            :icon="data.item.delivery_status | deliveryStatusIcon"
            :class="data.item.delivery_status | deliveryStatusClass"
            class="mr-1"
          />

          <span
            v-if="
              data.item.delivery_status !== 'assigning' &&
              data.item.delivery_option === 'pickup'
            "
          >
            Recoger en tienda
          </span>
          <span v-else>
            {{ data.item.delivery_status | deliveryStatus }}
          </span>
        </b-badge>
      </template>
    </template>

    <!-- Column: Invoiced status -->
    <template #cell(is_invoiced)="data">
      <template>
        <b-badge
          pill
          :class="data.item.is_invoiced | invoicedStatus"
          :variant="
            data.value === 'not_invoiced'
              ? 'light-warning'
              : data.value === 'partially_invoiced'
              ? 'light-info'
              : data.value === 'fully_invoided'
              ? 'light-success'
              : 'light-danger'
          "
        >
          {{ data.item.is_invoiced | invoicedStatus }}
        </b-badge>
      </template>
    </template>

    <!-- Column: Payment type -->
    <template #cell(order_payments_attributes)="data">
      <template>
        <b-badge
          v-if="data.item.order_payments_attributes[0]"
          pill
          :variant="
            data.item.order_payments_attributes[0].payment_type === 'cash'
              ? 'light-success'
              : data.item.order_payments_attributes[0].payment_type ===
                'walleat'
              ? 'light-info'
              : data.item.order_payments_attributes[0].payment_type ===
                  'bankcard' ||
                data.item.order_payments_attributes[0].payment_type ===
                  'bank_deposit'
              ? 'light-warning'
              : 'light-primary'
          "
        >
          {{
            data.item.order_payments_attributes[0].payment_type | paymentType
          }}
        </b-badge>
        <b-badge
          v-else
          pill
          :variant="
            data.item.payment_type === 'cash'
              ? 'light-success'
              : data.item.payment_type === 'walleat'
              ? 'light-info'
              : data.item.payment_type === 'bankcard' ||
                data.item.payment_type === 'bank_deposit'
              ? 'light-warning'
              : 'light-primary'
          "
        >
          {{ data.item.payment_type | paymentTypeNum }}
        </b-badge>
      </template>
    </template>

    <!-- Column: Is delivered? -->
    <template #cell(is_delivered)="data">
      <b-badge
        pill
        :variant="
          data.item.delivery_status === 'delivered'
            ? 'light-success'
            : 'light-danger'
        "
      >
        {{ data.item.delivery_status === "delivered" ? "Sí" : "No" }}
      </b-badge>
    </template>

    <!-- Column: Actions -->
    <template #cell(tools)="data">
      <div class="text-nowrap">
        <b-button
          :id="`invoice-row-${data.item.id}-preview`"
          :to="{ name: 'order-view', params: { id: data.item.id } }"
          :variant="'primary'"
        >
          Ver
        </b-button>
        <!--
          <b-tooltip
            :target="`invoice-row-${data.item.id}-preview`"
          >
            {{ "Dirección: " }}  {{ data.item.delivery_location.street }} {{ data.item.delivery_location.ext_number }} (num. int {{ data.item.delivery_location.int_number }} ), CP: {{ data.item.delivery_location.postal_code }}, {{ data.item.delivery_location.city }}, {{ data.item.delivery_location.state }}, {{ data.item.delivery_location.country }}

          </b-tooltip>
          -->
      </div>
    </template>
  </b-table>
</template>

<script>
export default {
  props: {
    orders: {
      type: Array,
      default: () => [],
    },
    userData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      columns: [
        "folio",
        {
          key: "store_clerk",
          label: "Cliente",
        },
        {
          key: "created_at",
          label: "Fecha",
        },
        {
          key: "delivery_date",
          label: "Fecha de entrega",
        },
        "total",
        {
          key: "payment_status",
          label: "Estado de pago",
        },
        {
          key: "delivery_status",
          label: "Estado de entrega",
        },
        {
          key: "order_payments_attributes",
          label: "Tipo de pago",
        },
        {
          key: "is_delivered",
          label: "¿Ya se entregó?",
        },
        {
          key: "is_invoiced",
          label: "¿Ya se facturó?",
        },
        {
          key: "tools",
          label: "Detalles de venta",
        },
      ],
    }
  },
}
</script>
