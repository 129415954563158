var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mx-1"},[_c('div',{staticClass:"mb-1 filters__container"},[_c('div',[_c('span',{staticClass:"mr-1"},[_vm._v("Folio")]),_c('b-form-input',{attrs:{"id":"v-folio","variant":"outline-primary","placeholder":"1234","type":"text"},on:{"input":_vm.onFolioChange},model:{value:(_vm.filters.folio),callback:function ($$v) {_vm.$set(_vm.filters, "folio", $$v)},expression:"filters.folio"}})],1),_c('div',[_c('span',{staticClass:"mr-1"},[_vm._v("Tipo de pago")]),_c('v-select',{staticClass:"invoice-filter-select",attrs:{"v-model":_vm.filters.payment_type,"dir":'ltr',"options":_vm.paymentTypeOptions,"reduce":function (option) { return option.value; },"clearable":false,"label":"text","placeholder":"Tipo de pago"},on:{"input":_vm.onPaymentTypeChange},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var text = ref.text;
return [_c('span',{staticClass:"text-truncate overflow-hidden"},[_vm._v(_vm._s(text))])]}},{key:"option",fn:function(ref){
var text = ref.text;
return [_c('span',[_vm._v(_vm._s(text))])]}}])})],1),_c('div',[_c('span',{staticClass:"mr-1"},[_vm._v("Entrega")]),_c('v-select',{staticClass:"invoice-filter-select",attrs:{"v-model":_vm.filters.delivery_status,"label":"text","dir":'ltr',"options":_vm.deliveryStatusOptions,"reduce":function (option) { return option.value; },"clearable":false,"placeholder":"Estado de entrega"},on:{"input":_vm.onDeliveryStatusChange},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var text = ref.text;
return [_c('span',{staticClass:"text-truncate overflow-hidden"},[_vm._v(_vm._s(text))])]}},{key:"option",fn:function(ref){
var text = ref.text;
return [_c('span',[_vm._v(_vm._s(text))])]}}])},[(_vm.loading)?_c('b-spinner',{attrs:{"small":"","label":"Loading"}}):_vm._e()],1)],1),_c('div',[_c('span',{staticClass:"mr-1"},[_vm._v("Estado")]),_c('v-select',{staticClass:"invoice-filter-select",attrs:{"v-model":_vm.filters.cancelation_status,"dir":'ltr',"label":"text","options":_vm.cancelationStatusOptions,"reduce":function (option) { return option.value; },"clearable":false,"placeholder":"¿Está cancelada?"},on:{"input":_vm.onCancelationStatusChange},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var text = ref.text;
return [_c('span',{staticClass:"text-truncate overflow-hidden"},[_vm._v(_vm._s(text))])]}},{key:"option",fn:function(ref){
var text = ref.text;
return [_c('span',[_vm._v(_vm._s(text))])]}}])})],1),_c('div',[_c('span',{staticClass:"mr-1"},[_vm._v("Tipo de orden")]),_c('v-select',{staticClass:"invoice-filter-select",attrs:{"v-model":_vm.filters.order_mode,"dir":'ltr',"label":"text","options":_vm.orderModeOptions,"reduce":function (option) { return option.value; },"clearable":false,"placeholder":"Tipo de orden"},on:{"input":_vm.onOrderModeChange},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var text = ref.text;
return [_c('span',{staticClass:"text-truncate overflow-hidden"},[_vm._v(_vm._s(text))])]}},{key:"option",fn:function(ref){
var text = ref.text;
return [_c('span',[_vm._v(_vm._s(text))])]}}])})],1),_c('div',[_c('span',{staticClass:"mr-1"},[_vm._v("Fecha de entrega")]),_c('b-form-datepicker',{attrs:{"locale":"es","placeholder":"Fecha de entrega","date-format-options":{
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        }},on:{"input":_vm.onDeliveryDateChange},model:{value:(_vm.filters.delivery_date),callback:function ($$v) {_vm.$set(_vm.filters, "delivery_date", $$v)},expression:"filters.delivery_date"}})],1)]),_c('div',{staticClass:"d-flex py-2 w-100 gap-2"},[_c('b-button',{attrs:{"disabled":_vm.loading,"variant":"outline-secondary"},on:{"click":function($event){return _vm.resetFilters()}}},[_vm._v(" Limpiar filtros ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }