<template>
  <div class="mx-1">
    <div class="mb-1 filters__container">
      <div>
        <span class="mr-1">Folio</span>
        <b-form-input
          id="v-folio"
          @input="onFolioChange"
          variant="outline-primary"
          placeholder="1234"
          type="text"
          v-model="filters.folio"
        />
      </div>
      <div>
        <span class="mr-1">Tipo de pago</span>
        <v-select
          @input="onPaymentTypeChange"
          :v-model="filters.payment_type"
          :dir="'ltr'"
          :options="paymentTypeOptions"
          :reduce="(option) => option.value"
          :clearable="false"
          label="text"
          class="invoice-filter-select"
          placeholder="Tipo de pago"
        >
          <template #selected-option="{ text }">
            <span class="text-truncate overflow-hidden">{{ text }}</span>
          </template>
          <template #option="{ text }">
            <span>{{ text }}</span>
          </template>
        </v-select>
      </div>
      <div>
        <span class="mr-1">Entrega</span>
        <v-select
          @input="onDeliveryStatusChange"
          :v-model="filters.delivery_status"
          label="text"
          :dir="'ltr'"
          :options="deliveryStatusOptions"
          :reduce="(option) => option.value"
          :clearable="false"
          class="invoice-filter-select"
          placeholder="Estado de entrega"
        >
          <b-spinner v-if="loading" small label="Loading" />
          <template #selected-option="{ text }">
            <span class="text-truncate overflow-hidden">{{ text }}</span>
          </template>
          <template #option="{ text }">
            <span>{{ text }}</span>
          </template>
        </v-select>
      </div>
      <div>
        <span class="mr-1">Estado</span>
        <v-select
          @input="onCancelationStatusChange"
          :v-model="filters.cancelation_status"
          :dir="'ltr'"
          label="text"
          :options="cancelationStatusOptions"
          :reduce="(option) => option.value"
          :clearable="false"
          class="invoice-filter-select"
          placeholder="¿Está cancelada?"
        >
          <template #selected-option="{ text }">
            <span class="text-truncate overflow-hidden">{{ text }}</span>
          </template>
          <template #option="{ text }">
            <span>{{ text }}</span>
          </template>
        </v-select>
      </div>
      <div>
        <span class="mr-1">Tipo de orden</span>
        <v-select
          @input="onOrderModeChange"
          :v-model="filters.order_mode"
          :dir="'ltr'"
          label="text"
          :options="orderModeOptions"
          :reduce="(option) => option.value"
          :clearable="false"
          class="invoice-filter-select"
          placeholder="Tipo de orden"
        >
          <template #selected-option="{ text }">
            <span class="text-truncate overflow-hidden">{{ text }}</span>
          </template>
          <template #option="{ text }">
            <span>{{ text }}</span>
          </template>
        </v-select>
      </div>
      <div>
        <span class="mr-1">Fecha de entrega</span>
        <b-form-datepicker
          v-model="filters.delivery_date"
          locale="es"
          placeholder="Fecha de entrega"
          :date-format-options="{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }"
          @input="onDeliveryDateChange"
        ></b-form-datepicker>
      </div>
    </div>
    <div class="d-flex py-2 w-100 gap-2">
      <b-button 
        :disabled="loading"
      variant="outline-secondary" @click="resetFilters()">
        Limpiar filtros
      </b-button>
      <!-- <b-button variant="primary" @click="applyFilters" :disabled="loading">
        Aplicar filtros
      </b-button> -->
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select"

export default {
  components: {
    vSelect,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      filters: {
        folio: "",
        delivery_status: "",
        cancelation_status: "",
        payment_type: "",
        order_mode: "",
        delivery_date: "",
      },
      paymentTypeOptions: [
        { value: "", text: "Todas" },
        { value: "cash", text: "Efectivo" },
        { value: "walleat", text: "Walleat" },
        { value: "bankcard", text: "Tarjeta bancaria" },
        { value: "bank_deposit", text: "Depósito/transferencia bancaria" },
        { value: "reward_points", text: "Puntos Walleat" },
      ],
      deliveryStatusOptions: [
        { value: "", text: "Todas" },
        { value: "assigning", text: "Por asignar" },
        { value: "assigned", text: "Repartidor asignado" },
        {
          value: "rejected_by_dispatcher",
          text: "Rechazado por el repartidor",
        },
        { value: "delivered", text: "Entregadas" },
      ],
      cancelationStatusOptions: [
        { value: "", text: "Todas" },
        { value: "true", text: "Canceladas" },
        { value: "false", text: "Activas" },
      ],
      orderModeOptions: [
        { value: "", text: "Todas" },
        { value: "online", text: "En línea" },
        { value: "onsite", text: "En sitio" },
        { value: "presale", text: "Preventa" },
      ],
    }
  },
  methods: {
    applyFilters() {
      this.$emit("applyFilters", this.filters)
    },

    onFolioChange(value) {
      this.applyFilters()
      this.$emit("update:folioFilter", value)
    },

    onOrderModeChange(value) {
      this.filters.order_mode = value
      this.applyFilters()

      this.$emit("update:orderModeFilter", value)
    },

    onPaymentTypeChange(value) {
      this.filters.payment_type = value
      this.applyFilters()

      this.$emit("update:paymentTypeFilter", value)
    },

    onDeliveryStatusChange(value) {
      this.filters.delivery_status = value
      this.applyFilters()

      this.$emit("update:deliveryStatusFilter", value)
    },

    onCancelationStatusChange(value) {
      this.filters.cancelation_status = value
      this.applyFilters()

      this.$emit("update:cancelationStatusFilter", value)
    },

    onDeliveryDateChange(value) {
      this.filters.delivery_date = value
      this.applyFilters()

      this.$emit("update:deliveryDateFilter", value)
    },

    resetFilters() {
      this.filters = {
        folio: "",
        delivery_status: "",
        cancelation_status: "",
        payment_type: "",
        order_mode: "",
        delivery_date: "",
      }
      this.$emit("applyFilters", this.filters)
    },
  },
}
</script>

<style lang="scss" scoped>
.filters__container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  align-items: center;
}
</style>
