var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"online-sale-transaction-modal","ok-only":""}},[(_vm.selectedOrder)?_c('b-card',{staticClass:"mb-0",attrs:{"title":"Detalles de la transacción","header-tag":"header","header-class":"p-2"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","md":"6"}},[_c('b-card-text',[_c('b',[_vm._v("Orden:")]),_vm._v(" #"+_vm._s(_vm.selectedOrder.auto_inc_folio)+" ")]),_c('b-card-text',[_c('b',[_vm._v("Fecha:")]),_vm._v(" "+_vm._s(_vm._f("dateNtime")(_vm.selectedOrder.created_at))+" ")]),_c('b-card-text',[_c('b',[_vm._v("Cliente:")]),_vm._v(" "+_vm._s(_vm.selectedOrder.store_clerk.name)+" ")]),_c('b-card-text',[_c('b',[_vm._v("Correo:")]),_vm._v(" "+_vm._s(_vm.selectedOrder.store_clerk.email)+" ")]),_c('b-card-text',[_c('b',[_vm._v("Total: ")]),_vm._v(" "+_vm._s(_vm._f("money")(_vm.selectedOrder.total))+" ")]),_c('b-card-text',[_c('b',[_vm._v("Estado de pago: ")]),_c('b-badge',{attrs:{"pill":"","variant":_vm.selectedOrder.payment_status === 'payed'
                ? 'light-success'
                : _vm.selectedOrder.payment_status === 'pending'
                ? 'light-warning'
                : 'light-danger'}},[_vm._v(" "+_vm._s(_vm._f("paymentStatus")(_vm.selectedOrder.payment_status))+" ")])],1),_c('b-card-text',[_c('b',[_vm._v("Estado de entrega: ")]),_c('b-badge',{staticClass:"whitespace-normal",class:_vm._f("deliveryStatusClass")(_vm.selectedOrder.delivery_status),attrs:{"pill":"","variant":_vm.selectedOrder.delivery_status === 'delivered'
                ? 'light-success'
                : _vm.selectedOrder.delivery_status === 'assigning'
                ? 'light-warning'
                : _vm.selectedOrder.delivery_status === 'assigned'
                ? 'light-primary'
                : _vm.selectedOrder.delivery_status ===
                    'rejected_by_dispatcher' ||
                  _vm.selectedOrder.delivery_status === 'cancelled_by_customer' ||
                  _vm.selectedOrder.delivery_status ===
                    'cancelled_by_establishment'
                ? 'light-danger'
                : 'light-info'}},[_c('feather-icon',{staticClass:"mr-1",class:_vm._f("deliveryStatusClass")(_vm.selectedOrder.delivery_status),attrs:{"icon":_vm._f("deliveryStatusIcon")(_vm.selectedOrder.delivery_status)}}),_vm._v(" "+_vm._s(_vm._f("deliveryStatus")(_vm.selectedOrder.delivery_status))+" ")],1)],1),(_vm.selectedOrder.dispatcher_name)?_c('b-card-text',[_c('b',[_vm._v("Repartidor: ")]),(
              _vm.selectedOrder.dispatcher_id &&
              _vm.selectedOrder.delivery_status !== 'rejected_by_dispatcher'
            )?_c('b-badge',{class:_vm._f("deliveryStatusClass")(_vm.selectedOrder.delivery_status),attrs:{"pill":"","variant":_vm.selectedOrder.delivery_status === 'delivered'
                ? 'light-success'
                : _vm.selectedOrder.delivery_status === 'assigning'
                ? 'light-warning'
                : _vm.selectedOrder.delivery_status === 'assigned'
                ? 'light-primary'
                : _vm.selectedOrder.delivery_status === 'rejected_by_dispatcher'
                ? 'light-danger'
                : 'light-info'}},[_vm._v(" "+_vm._s(_vm.selectedOrder.dispatcher.name)+" ")]):_vm._e()],1):_vm._e(),_c('b-card-text',[_c('b',[_vm._v("Tipo de pago: ")]),(_vm.selectedOrder.order_payments_attributes[0])?_c('b-badge',{attrs:{"pill":"","variant":_vm.selectedOrder.order_payments_attributes[0].payment_type ===
              'cash'
                ? 'light-success'
                : _vm.selectedOrder.order_payments_attributes[0].payment_type ===
                  'walleat'
                ? 'light-info'
                : _vm.selectedOrder.order_payments_attributes[0].payment_type ===
                    'bankcard' ||
                  _vm.selectedOrder.order_payments_attributes[0].payment_type ===
                    'bank_deposit'
                ? 'light-warning'
                : 'light-primary'}},[_vm._v(" "+_vm._s(_vm._f("paymentType")(_vm.selectedOrder.order_payments_attributes[0].payment_type))+" ")]):_c('b-badge',{attrs:{"pill":"","variant":_vm.selectedOrder.payment_type === 'cash'
                ? 'light-success'
                : _vm.selectedOrder.payment_type === 'walleat'
                ? 'light-info'
                : _vm.selectedOrder.payment_type === 'bankcard' ||
                  _vm.selectedOrder.payment_type === 'bank_deposit'
                ? 'light-warning'
                : 'light-primary'}},[_vm._v(" "+_vm._s(_vm._f("paymentTypeNum")(_vm.selectedOrder.payment_type))+" ")])],1)],1)],1),_c('div',[(
          _vm.selectedOrder.delivery_status !== 'delivered' &&
          _vm.userData.role_name !== 'establishment_presale_clerk'
        )?_c('b-button',{attrs:{"variant":'success',"size":"sm"},on:{"click":function($event){return _vm.markAsDelivered(_vm.selectedOrder)}}},[_vm._v(" Marcar como entregado ")]):_vm._e()],1),_c('div',{staticClass:"mt-1"},[(
          _vm.selectedOrder.delivery_status == 'assigned' &&
          _vm.userData.role_name !== 'establishment_presale_clerk'
        )?_c('b-button',{attrs:{"variant":'primary',"size":"sm"},on:{"click":function($event){return _vm.selectDispatcher(_vm.selectedOrder)}}},[_vm._v(" Cambiar ")]):(
          _vm.selectedOrder.dispatcher_id &&
          _vm.selectedOrder.delivery_status === 'assigning' &&
          _vm.userData.role_name !== 'establishment_presale_clerk'
        )?_c('b-button',{attrs:{"variant":'success',"size":"sm"},on:{"click":function($event){return _vm.openConfirmDispatcherModal(_vm.selectedOrder)}}},[_vm._v(" Confirmar ")]):(
          (_vm.selectedOrder.delivery_status == 'rejected_by_dispatcher' ||
            (!_vm.selectedOrder.dispatcher_id &&
              _vm.selectedOrder.delivery_status !== 'delivered')) &&
          _vm.userData.role_name !== 'establishment_presale_clerk'
        )?_c('b-button',{attrs:{"variant":'warning',"size":"sm"},on:{"click":function($event){return _vm.selectDispatcher(_vm.selectedOrder)}}},[_vm._v(" Asignar ")]):_vm._e()],1),_c('div',{staticClass:"mt-1"},[_c('b-button',{attrs:{"id":("invoice-row-" + (_vm.selectedOrder.id) + "-preview"),"to":{ name: 'order-view', params: { id: _vm.selectedOrder.id } },"variant":'primary',"size":"sm"}},[_vm._v(" Ver detalles ")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }