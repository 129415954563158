<template>
  <b-list-group class="sales-list px-1 mt-2">
    <b-list-group-item
      v-for="order in orders"
      :key="order.id"
      class="d-flex flex-column flex-gap"
      @click="handleSelectedTransaction(order)"
    >
      <b-link
        :to="{ name: 'order-view', params: { id: order.id } }"
        class="font-weight-bold"
      >
        #{{ order.folio || order.auto_inc_folio }}
      </b-link>
      <div>
        <p class="text-sm text-break">
          {{ order.store_clerk.name }}
        </p>
      </div>
      <div>
        <p class="text-sm text-break">{{ order.store_clerk.email }}</p>
      </div>
      <div>
        <feather-icon
          :icon="order.order_type | orderTypeIcon"
          :class="order.order_type | orderTypeClass"
          class="mr-50"
        />
        ${{ order.total | money }}
      </div>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
export default {
  props: {
    orders: {
      type: Array,
      default: () => [],
    },
    handleSelectedTransaction: {
      type: Function,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.flex-gap {
  gap: 0.5rem;
}
</style>
