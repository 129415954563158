<template>
  <b-card no-body>
    <header class="mx-2 mt-2 mb-0">
      <div class="d-flex justify-between">
        <div
          cols="12"
          sm="auto"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <h1>Administrar Pedidos</h1>
        </div>

        <!-- Search -->
        <div
          class="
            d-flex
            flex-column flex-sm-row
            align-items-start
            justify-content-end
          "
        >
          <slot />
        </div>

        <div>
          <!-- <b-button variant="outline-secondary" @click="downloadFile()" class="d-btn-sm">
            <span class="d-md-none">
              <feather-icon icon="DownloadIcon" />
            </span>
            <span class="d-md-block d-none">Actualizar</span>
          </b-button> -->

          <b-button variant="outline-secondary" @click="downloadFile()">
            <span class="d-md-none">
              <feather-icon icon="DownloadIcon" />
            </span>

            <span class="d-md-block d-none">Exportar</span>
          </b-button>
        </div>
      </div>
    </header>
    <mark-as-delivered-modal
      :order="order"
      :orderTotal="orderTotal"
      :confirmsDelivery="confirmsDelivery"
    />
    <!-- Assign dispatcher modal-->
    <assign-dispatcher-modal
      :delivery_address="delivery_address"
      :onSubmit="onSubmit"
      :order="order"
      :dispatchers="dispatchers"
      :closeSelectDispatcherModal="closeSelectDispatcherModal"
    />

    <!-- Confirm dispatcher modal-->
    <confirm-dispatcher-modal
      :changeDispatcher="changeDispatcher"
      :assignDispatcher="assignDispatcher"
      :dispatcher_name="dispatcher_name"
      :delivery_address="delivery_address"
    />

    <b-tabs class="p-1">
      <b-tab title="Pedidos recibidos" active>
        <online-sales-transactions-filters
          :loading="loading"
          @applyFilters="applyFilters"
        />

        <div v-if="!loading">
          <online-sales-transactions-table
            :orders="ordersShown"
            :userData="userData"
            :selectDispatcher="selectDispatcher"
            :openConfirmDispatcherModal="openConfirmDispatcherModal"
            :markAsDelivered="markAsDelivered"
          />

          <online-sales-transactions-list
            :orders="ordersShown"
            :handleSelectedTransaction="handleSelectedTransaction"
          />

          <div class="mx-2 mb-2 mt-1">
            <pagination
              :pagination="pagination"
              :loading="loading"
              :entriesPerPage.sync="entriesPerPage"
              :handlePagination="handlePagination"
            />
          </div>
        </div>
        <div v-else>
          <b-skeleton-table :rows="10" :columns="5" />
        </div>
      </b-tab>
      <b-tab title="Pedidos realizados">
        <div v-if="!buyOrdersLoading">
          <buy-orders-table :orders="buyOrders" />

          <div class="mx-2 mb-2 mt-1">
            <pagination
              :pagination="buyOrdersPagination"
              :loading="buyOrdersLoading"
              :entriesPerPage.sync="buyEntriesPerPage"
              :handlePagination="handleBuyOrdersPagination"
            />
          </div>
        </div>
        <div v-else>
          <b-skeleton-table :rows="10" :columns="5" />
        </div>
      </b-tab>
    </b-tabs>

    <online-sale-transaction-modal
      :selectedOrder="selectedOrder"
      :userData="userData"
      :openConfirmDispatcherModal="openConfirmDispatcherModal"
      :markAsDelivered="markAsDelivered"
      :selectDispatcher="selectDispatcher"
    />
  </b-card>
</template>

<script>
import vSelect from "vue-select"
import { ValidationObserver } from "vee-validate"
import { mapActions, mapGetters, mapMutations } from "vuex"

import ConfirmDispatcherModal from "./ConfirmDispatcherModal.vue"
import AssignDispatcherModal from "./AssignDispatcherModal.vue"
import MarkAsDeliveredModal from "./MarkAsDeliveredModal.vue"
import OnlineSalesTransactionsTable from "./OnlineSalesTransactionsTable.vue"
import OnlineSalesTransactionsList from "./OnlineSalesTransactionsList.vue"
import OnlineSalesTransactionsFilters from "./OnlineSalesTransactionsFilters.vue"
import OnlineSaleTransactionModal from "./OnlineSaleTransactionModal.vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import Pagination from "@/@core/components/Pagination.vue"
import BuyOrdersTable from "./buy-orders-table.vue"

import { required } from "@validations"
import debounce from "lodash/debounce"

export default {
  components: {
    vSelect,

    BuyOrdersTable,
    OnlineSalesTransactionsTable,
    OnlineSalesTransactionsList,
    OnlineSalesTransactionsFilters,
    OnlineSaleTransactionModal,
    ConfirmDispatcherModal,
    AssignDispatcherModal,
    MarkAsDeliveredModal,
    Pagination,

    ValidationObserver,
  },
  props: {
    dates: {
      type: [Array, String],
      default: () => null,
    },
  },
  data() {
    return {
      required,
      ordersShown: null,
      loading: false,
      buyOrdersLoading: false,
      userData: JSON.parse(localStorage.getItem("userData")),
      entriesPerPage: "10",
      buyEntriesPerPage: "10",
      searchQuery: "",
      paymentTypeFilter: "",
      deliveryStatusFilter: "",
      folioFilter: "",
      cancelationStatusFilter: "",
      orderModeFilter: "",
      dispatchers: null,
      orderTotal: null,
      deliveryDateFilter: "",
      delivery_address: null,
      dispatcher_name: null,
      order: {
        id: null,
        dispatcher_id: null,
      },
      selectedOrder: null,
      filters: {
        folio: "",
        delivery_status: "",
        cancelation_status: "",
        payment_type: "",
        order_mode: "",
        delivery_date: "",
      },
      buyOrders: [],
      buyOrdersPagination: {},
    }
  },

  beforeMount() {
    Promise.allSettled([
      this.getOrders(),
      this.getBuyOrders(),
      this.fetchUsers({
        by_resource: this.$route.params.id,
        by_active_status: true,
      }),
      new Promise((resolve) => {
        const roles = ["admin", "establishment_admin"]
        if (roles.includes(this.userData.role_name.toLowerCase())) {
          resolve(this.fetchStore(this.$route.params.id))
        } else {
          resolve()
        }
      }),
    ])
  },

  computed: {
    ...mapGetters("orders", ["pagination", "orders"]),
    ...mapGetters("users", ["users"]),
    ...mapGetters("stores", ["currentStore"]),
  },
  watch: {
    entriesPerPage() {
      this.getOrders()
    },
    buyEntriesPerPage() {
      this.getBuyOrders()
    },
  },

  mounted() {
    this.ordersShown = this.orders
    this.onlineOrdersConnection = new WebSocket(
      `${process.env.VUE_APP_WSS_URL}/cable?token=${this.userData.token}`
    )
    sessionStorage.setItem(
      "wsConnectionOnlineOrders",
      JSON.stringify(this.onlineOrdersConnection)
    )

    this.onlineOrdersConnection.onmessage = (event) => {
      const messagex = JSON.parse(event.data)
      if (typeof messagex.message === "object") {
        this.$swal({
          title: messagex.message.title,
          text: messagex.message.content_message,
          icon: "warning",
          showCancelButton: false,
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        })

        this.getOrders()
      }
    }

    this.onlineOrdersConnection.onopen = () => {
      // eslint-disable-next-line
      console.log("Successfully connected to the echo websocket server...")

      // eslint-disable-next-line
      this.onlineOrdersConnection.send(
        JSON.stringify({
          command: "subscribe",
          identifier: '{"channel":"ApplicationCable::MyChannel"}',
        })
      )
    }
  },
  destroyed() {
    this.onlineOrdersConnection.close()
    this.deleteOrders()
  },
  methods: {
    ...mapActions("orders", [
      "fetchOrders",
      "deliveryConfirmation",
      "confirmDispatcher",
      "generateOrdersConciliationFile",
    ]),
    ...mapActions("users", ["fetchUsers"]),
    ...mapActions("stores", ["fetchStore"]),
    ...mapMutations("orders", ["deleteOrders"]),

    applyFilters(filters) {
      this.filters = { ...filters }
      this.loading = true
      this.debounceFilters()
    },

    debounceFilters: debounce(function ({ page = 1 } = {}) {
      this.getOrders({ page })
    }, 500),

    getOrders({ page = 1 } = {}) {
      this.loading = true

      let dates
      if (this.dates) {
        dates = {
          start_date: Array.isArray(this.dates)
            ? this.dates[0]
            : this.dates.substring(0, 10),
          end_date: Array.isArray(this.dates)
            ? this.dates[1]
            : this.dates.substring(14),
        }
      } else {
        dates = null
      }

      this.fetchOrders({
        by_order_type: "sell",
        by_store: this.$route.params.id,
        by_date: dates,
        by_folio: this.filters.folio,
        by_delivery_status: this.filters.delivery_status,
        by_payment_type: this.filters.payment_type,
        by_is_refund: this.filters.cancelation_status,
        by_order_mode: this.filters.order_mode,
        by_delivery_date: this.filters.delivery_date,
        meta: {
          pagination: {
            page: page || 1,
            per_page: this.entriesPerPage,
          },
        },
      })
        .then(() => {
          this.ordersShown = this.orders
        })
        .finally(() => {
          this.loading = false
        })
    },

    getBuyOrders({ page = 1 } = {}) {
      this.buyOrdersLoading = true

      this.fetchOrders({
        by_store: this.$route.params.id,
        by_folio: this.filters.folio,
        by_delivery_status: this.filters.delivery_status,
        by_payment_type: this.filters.payment_type,
        by_is_refund: this.filters.cancelation_status,
        by_order_mode: this.filters.order_mode,
        by_delivery_date: this.filters.delivery_date,
        by_order_type: "buy",
        meta: {
          pagination: {
            page: page || 1,
            per_page: this.entriesPerPage,
          },
        },
      })
        .then((response) => {
          this.buyOrders = response.data
          this.buyOrdersPagination = response.meta.pagination
        })
        .finally(() => {
          this.buyOrdersLoading = false
        })
    },

    downloadFile() {
      this.generateOrdersConciliationFile({
        by_store: this.$route.params.id,
        by_delivery_status: this.deliveryStatusFilter,
        by_payment_type: this.paymentTypeFilter,
        by_is_refund: this.cancelationStatusFilter,
      }).then((response) => {
        const csvContent = `data:text/csv;charset=utf-8,${response.split(",")}`
        const encodedUri = encodeURI(csvContent)
        window.open(encodedUri)
      })
    },
    handleSelectedTransaction(order) {
      this.selectedOrder = order
      this.$bvModal.show("online-sale-transaction-modal")
    },
    handlePagination({ page }) {
      this.getOrders({ page })
    },
    handleBuyOrdersPagination({ page }) {
      this.getBuyOrders({ page })
    },
    formatDate(date) {
      const d = new Date(date)
      let month = `${d.getMonth() + 1}`
      let day = `${d.getDate()}`
      const year = d.getFullYear()

      if (month.length < 2) month = `0${month}`
      if (day.length < 2) day = `0${day}`

      return [year, month, day].join("-")
    },
    formatFirstDate(date) {
      const d = new Date(date)
      let month = `${d.getMonth() + 1}`
      let day = "1"
      const year = d.getFullYear()

      if (month.length < 2) month = `0${month}`
      if (day.length < 2) day = `0${day}`

      return [year, month, day].join("-")
    },
    changeDispatcher() {
      this.defineDispatchers()
      this.$bvModal.hide("online-sale-transaction-modal")
      this.$bvModal.show("select-dispatcher")
    },
    selectDispatcher(order) {
      this.defineDispatchers()
      this.order.id = order.id
      this.delivery_address = [
        `${order.delivery_location?.street} ${order.delivery_location?.ext_number}`,
        order.delivery_location?.int_number
          ? `(num. int ${order.delivery_location.int_number})`
          : "",
        `CP: ${order.delivery_location?.postal_code}`,
        order.delivery_location?.city,
        order.delivery_location?.state,
        order.delivery_location?.country,
      ]
        .filter(Boolean)
        .join(", ")

      this.$bvModal.hide("online-sale-transaction-modal")
      this.$bvModal.show("select-dispatcher")
    },
    markAsDelivered(order) {
      this.order.id = order.id
      this.orderTotal = order.total
      this.order.payment_status = order.payment_status
      this.$bvModal.hide("online-sale-transaction-modal")
      this.$bvModal.show("mark-as-delivered")
    },
    defineDispatchers() {
      this.dispatchers = this.users // .filter(item => item.role_name.toLowerCase().includes('dispatcher'))
    },
    closeSelectDispatcherModal() {
      this.$bvModal.hide("select-dispatcher")
    },
    openConfirmDispatcherModal(order) {
      this.order.id = order.id
      this.order.dispatcher_id = order.dispatcher_id
      this.delivery_address = `${order.delivery_location.street} ${order.delivery_location.ext_number} (num. int ${order.delivery_location.int_number}),
        CP: ${order.delivery_location.postal_code}, ${order.delivery_location.city}, ${order.delivery_location.state}, ${order.delivery_location.country} `
      this.dispatcher_name = order.dispatcher.name
      this.$bvModal.hide("online-sale-transaction-modal")
      this.$bvModal.show("confirm-dispatcher")
    },
    onSubmit() {
      this.assignDispatcher()
      this.$bvModal.hide("select-dispatcher")
    },
    confirmsDelivery() {
      this.deliveryConfirmation({
        id: this.order.id,
      })
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          this.$swal({
            title: "Orden marcada como entrega!",
            icon: "success",
            customClass: {
              confirmButton: "btnx btn-primary",
            },
            buttonsStyling: false,
          })

          this.getOrders()
          this.paymentTypeFilter = ""
          this.deliveryStatusFilter = ""
          this.folioFilter = ""
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Error",
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.messages.base[0],
            },
          })
        })
    },
    assignDispatcher() {
      this.$bvModal.hide("confirm-dispatcher")
      this.confirmDispatcher(this.order)
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          this.$swal({
            title: "Orden asignada a repartidor!",
            text: "Has asignado correctamente un repartidor a la orden; espera que el repartidor confirme la orden",
            icon: "success",
            customClass: {
              confirmButton: "btnx btn-primary",
            },
            buttonsStyling: false,
          })
          this.order.id = null
          this.order.dispatcher_id = null
          this.delivery_address = null
          this.dispatcher_name = null
          this.getOrders()
          this.paymentTypeFilter = ""
          this.deliveryStatusFilter = ""
          this.folioFilter = ""
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Error",
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.messages.base[0],
            },
          })
          this.order.id = null
          this.order.dispatcher_id = null
          this.delivery_address = null
          this.dispatcher_name = null
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";

.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.search-input {
  max-width: 200px;
}

.sales-table {
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
}

.sales-list {
  display: block;

  @media (min-width: 768px) {
    display: none;
  }
}
</style>
